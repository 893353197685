import React from "react";
import theme from "theme";
import { Theme, Image, Text, Icon, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsDot } from "react-icons/bs";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Главная | Добро пожаловать домой с Horizon Realty
			</title>
			<meta name={"description"} content={"Откройте для себя идеальное пространство вместе с нами"} />
			<meta property={"og:title"} content={"Главная | Добро пожаловать домой с Horizon Realty"} />
			<meta property={"og:description"} content={"Откройте для себя идеальное пространство вместе с нами"} />
			<meta property={"og:image"} content={"https://brewaup.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://brewaup.com/img/2389056-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brewaup.com/img/2389056-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brewaup.com/img/2389056-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brewaup.com/img/2389056-200.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-13">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Image
					src="https://brewaup.com/img/1.jpg"
					display="block"
					width="40%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="flex-end"
					width="60%"
					padding="80px 80px 80px 80px"
					lg-width="100%"
					md-padding="50px 50px 80px 50px"
					sm-padding="50px 25px 80px 25px"
				>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						align-items="center"
						margin="0px 0px 25px 0px"
						sm-flex-direction="column"
						sm-align-items="flex-start"
					>
						<Text
							margin="0px 7px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sans"
							color="#061e11"
							sm-margin="0px 0 7px 0px"
							letter-spacing="1px"
						>
							16 ЛЕТ ОПЫТА
						</Text>
						<Icon
							category="bs"
							icon={BsDot}
							size="24px"
							margin="0px 7px 0px 0px"
							sm-display="none"
						/>
						<Text margin="0px 0px 0px 0px" font="normal 400 16px/1.5 --fontFamily-sans" color="#061e11" letter-spacing="1px">
							ВЕСЬ СПЕКТР УСЛУГ
						</Text>
					</Box>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 72px/1.2 --fontFamily-serifGeorgia"
						color="#061e11"
						lg-margin="0px 0px 35px 0px"
						md-font="normal 500 52px/1.2 --fontFamily-serifGeorgia"
						sm-font="normal 500 42px/1.2 --fontFamily-serifGeorgia"
					>
						Horizon Realty
					</Text>
					<Text margin="0px 0px 40px 0px" font="normal 300 20px/1.3 --fontFamily-sans" color="#061e11" letter-spacing="1px">
						Добро пожаловать в Horizon Realty, где поиск дома вашей мечты - это не просто сделка, это целый опыт.
В Horizon Realty мы понимаем, что дом - это больше, чем просто четыре стены и крыша. Это место, где разворачивается ваша история, где создаются воспоминания и где вы находите убежище от суеты и шума внешнего мира. Перейдите в мир, где комфорт, стиль и удобство объединяются, чтобы создать идеальный дом для вас.
					</Text>
					<Link href="/contacts" color="--darkL1" font="normal 600 28px/1.2 --fontFamily-serifGeorgia">
						Свяжитесь с нами &gt;&gt;
					</Link>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="37%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Погрузитесь в Horizon Realty
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="63%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Пока вы ориентируетесь в огромном количестве вариантов недвижимости, наше обещание остается неизменным: прозрачность, честность и искреннее желание видеть, как вы процветаете в своем новом доме. Мы здесь не просто для того, чтобы проводить сделки - мы здесь для того, чтобы строить прочные отношения, основанные на доверии и взаимном уважении. Добро пожаловать в место, где ваши стремления взлетают ввысь, а ваше путешествие домой начинается с нами.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline2" margin="0 0 8px 0">
							Почему стоит выбрать Horizon Realty?
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Индивидуальный подход
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Наша преданная команда сопровождает вас на каждом этапе вашего путешествия по недвижимости, гарантируя, что ваши потребности и предпочтения будут удовлетворены с точностью и заботой.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Удачное местоположение
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--greyD2">
								Ознакомьтесь с нашим разнообразным портфелем недвижимости, расположенной в востребованных районах, предлагающих удобный доступ к удобствам, школам и транспорту.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Прозрачные сделки
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Доверие - основа нашего бизнеса. Мы верим в четкое общение и честность, чтобы вы могли чувствовать себя уверенно и информированно на протяжении всего процесса покупки, продажи или аренды.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Экспертиза и опыт
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--greyD2">
									Благодаря многолетнему опыту работы в сфере недвижимости мы обладаем знаниями и пониманием, чтобы помочь вам принять взвешенные решения и достичь ваших целей.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});